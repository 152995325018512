// Core variables and mixins
@import "variables.scss";
@import "mixins.scss";
// Global CSS
@import "global.scss";
@import "nav.scss";
@import "resume-item.scss";
@import "bootstrap-overrides.scss";
// Components
.devicon-xamarin-plain {
    width: 48px;
    height: 48px;
    vertical-align: baseline;
}


/* if you want to change the original color */

.devicon-xamarin-plain path {
    fill: $secondary;
    &:hover {
        fill: darken($secondary, 15%);
    }
}

#form-messages {
    visibility: hidden;
}

form #website{ display:none; }

#img-profile-container {
    text-align: center;
}

.img-profile {
    margin-top: 3rem;
    max-width: 15rem;
    max-height: 15rem;
    border: 0.5rem solid lighten($primary, 20%);
}

.list-inline-item.xamarin {
    vertical-align: middle;
}

#claps-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    padding: 0.5rem;
}

#claps-container .post,
.post:link,
.post:visited,
.post:hover,
.post:focus,
.post:active {
    color: inherit;
    text-decoration: none;
    opacity: 0;
    transition: opacity 750ms ease-in-out;
}

#claps-container .post.is-visible {
    opacity: 1;
}

#claps-container .post-container {
    display: flex;
    flex-direction: row;
    width: 300px;
    border-radius: 2px;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3), 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    margin: 0.5rem;
}

#claps-container .post-image {
    /*  */
    width: 100%;
    height: 100px;
    border-radius: 2px;
}

#claps-container .post-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

#claps-container .post-title {
    width: 100%;
}

#claps-container .post-title h2 {
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0 0.5rem 0.5rem 0.5rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

#claps-container .post-author {
    width: 100%;
}

#claps-container .post-author p {
    font-size: 0.7rem;
    font-weight: 300;
    margin: 0 0.5rem 0.5rem 0.5rem;
    text-align: left;
}