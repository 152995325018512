.bg-primary {
  background-color: $primary !important;
}

.text-primary {
  color: $primary !important;
}

.text-secondary {
  color: $secondary !important;
}

a {
  color: $secondary;
  &:hover,
  &:focus,
  &:active {
    color: darken($secondary, 15%);
  }
}

.btn-primary {
  background-color: $secondary;
  border-color: lighten($secondary, 15%);
  &:hover,
  &:focus,
  &:active {
    opacity: 1;
    background-color: darken($secondary, 15%);
    border-color: lighten($secondary, 15%);
  }
}