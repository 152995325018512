section.resume-section {
  border-bottom: 1px solid $gray-300;
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
  .resume-item {
    .resume-content {}
    .resume-date {
      min-width: none;
    }
  }
}
@media (min-width: 768px) {
  section.resume-section {
    min-height: 100vh;
    .resume-item {
      .resume-content {}
      .resume-date {
        min-width: 18rem;
      }
    }
  }
}
@media (min-width: 992px) {
  section.resume-section {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
}
