// Global styling for this template

body {
  @include body-font;
  padding-top: 54px;
  color: $text-color;
}
@media (min-width: 992px) {
  body {
    padding-top: 0;
    padding-left: $sidebar-base-width;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include heading-font;
  font-weight: 700;
  text-transform: uppercase;
  color: $text-color;
}

h1 {
  font-size: 6rem;
  line-height: 5.5rem;
}

h2 {
  font-size: 3.5rem;
}

.subheading {
  text-transform: uppercase;
  font-weight: 500;
  @include heading-font;
  font-size: 1.35rem;
}

.list-social-icons {
  a {
    color: $secondary;
    &:hover {
      color: darken($secondary, 15%);
    }
    .fa-lg {
      font-size: 1.75rem;
    }
  }
}

.list-icons {
  font-size: 3rem;
  color: $secondary;
  .list-inline-item i {
    &:hover {
      color: darken($secondary, 15%);
    }
  }
}
